import { call, put, takeLatest } from "redux-saga/effects";
import {
  callApiFailure,
  callApiRequest,
  callApiSuccess,
} from "../itinerarySearchSlice";
import globalAxiosURL from "@/hooks/globalAxiosURL";

// Worker saga to handle the API call
function* fetchItinerariesSaga(action) {
  const axios = globalAxiosURL();
  try {
    const axiosPublic = globalAxiosURL();
    // Perform the API request using axios
    const response = yield call(
      axiosPublic.post,
      "/itineraries/itineraries/partner",
      action.payload
    );

    // Dispatch success action with response data
    yield put(callApiSuccess(response.data));
  } catch (error) {
    // Dispatch failure action with error message
    yield put(callApiFailure(error.response?.data || "An error occurred"));
  }
}

// Watcher saga to listen for the callApiRequest action
export default function* itinerarySaga() {
  yield takeLatest(callApiRequest.type, fetchItinerariesSaga);
}
