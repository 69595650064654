"use client";
import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useSelector, useDispatch } from "react-redux";
import { IoClose } from "react-icons/io5";
import logo from "../../public/hams-Logo-white.webp";
import black_logo from "../../public/logo-black.png";
// import Login from "@/components/singIn/Login";
import userProfile from "../../public/profile.png";
import { usePathname } from "next/navigation";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import { setDashboardData } from "@/redux/dashboardData";
import { setAgentData } from "@/redux/getAgent";
import { LuMenu } from "react-icons/lu";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const dispatch = useDispatch();
  const { isLoggedIn, email } = useSelector((state) => state.auth);
  const pathName = usePathname();
  const axiosURL = globalAxiosURL();
  const { agentData } = useSelector((state) => state.agentInfo);
  const menuRef = useRef(null);
  const [isContactPopupOpen, setIsContactPopupOpen] = useState(false);
  const [assignedRepresentative, setAssignedRepresentative] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const fetchUserData = async () => {
    if (email) {
      const res = await axiosURL.get(`/dashboard/data?email=${email}`);
      // console.log(res.data);
      dispatch(setDashboardData(res.data));
      // setAmount(res?.data?.result[0]?.totalAmountPaid);
    }
  };

  const { dashboardData } = useSelector((state) => state.dashboardData);

  //
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 100 && currentScrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  // Clicking outside
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
      document.addEventListener("click", handleClickOutside, true);
    } else {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("click", handleClickOutside, true);
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOpen]);

  useEffect(() => {
    const fetchAssignment = async () => {
      try {
        const { data } = await axiosURL.get(
          `/assign-representative/${agentData._id}`
        );

        if (data.status === false) {
          setAssignedRepresentative(null);
        }

        if (data.status === true) {
          setAssignedRepresentative(data.data.representativeId);
        }
      } catch (error) {
        // console.log("Error", error);
      }
    };

    fetchAssignment();
  }, [axiosURL, agentData]);

  const links = [
    {
      href: "/",
      text: "Flights",
    },
  ];

  const getUser = async () => {
    if (email) {
      const { data } = await axiosURL.get(
        `/agentAuth/get-agent?email=${email}`
      );
      dispatch(setAgentData(data));
    }
  };

  useEffect(() => {
    fetchUserData();
    getUser();
  }, [email]);

  return (
    <main>
      <nav
        className={`bg-[#00703E] fixed top-0 w-full z-[9999] h-[60px] transition-transform duration-200 ${
          showNavbar ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <div className="p-1 flex items-center justify-between lg:px-16 md:px-10 px-3 mx-auto h-full">
          <div className="flex relative items-center" ref={menuRef}>
            <div
              onClick={() => setIsOpen(!isOpen)}
              className="inline-block lg:hidden text-white pr-2"
            >
              <LuMenu size={20} />
            </div>
            <div
              className={`fixed h-screen shadow-[0_3px_10px_rgb(0,0,0,0.2)] top-0 left-0 bg-[#F6FBF4] text-black py-5 w-[270px] sm:w-[270px] transform ${
                isOpen ? "translate-x-0 overflow-y-auto" : "-translate-x-full"
              } transition-transform flex flex-col justify-between duration-300 ease-in-out z-10 shadow-xl`}
            >
              <div>
                <div
                  onClick={() => setIsOpen(false)}
                  className="flex justify-between"
                >
                  <Link href="/">
                    <Image
                      src={black_logo}
                      alt="Logo"
                      className="w-28 object-contain"
                    />
                  </Link>
                  <div className="p-2 rounded-full mr-2">
                    <IoClose size={20} />
                  </div>
                </div>
                <ul className="flex mt-6 flex-col font-medium border-b">
                  {links.map((li) => (
                    <Link
                      href={li.href}
                      onClick={() => setIsOpen(false)}
                      key={li.text}
                      className={`py-2 text-sm px-5 border-t inline-block hover:bg-[#ff5e000e] ${
                        pathName === li.href ? "text-base font-semibold" : ""
                      }`}
                    >
                      {li.text}
                    </Link>
                  ))}
                </ul>
              </div>

              <div className="mt-10 text-sm pl-2">
                <h2 className="text-lg my-5 font-semibold">
                  Balance: {dashboardData?.accountBalance?.toLocaleString()}
                </h2>
                <h2 className="text-xl font-bold">Your Executive</h2>
                {assignedRepresentative ? (
                  <div className="space-y-2 mt-4">
                    <h4>
                      <span className="font-semibold">Name:</span>{" "}
                      {assignedRepresentative.name}
                    </h4>
                    <h4>
                      <span className="font-semibold">Email:</span>{" "}
                      <a
                        className="text-green-700 underline underline-offset-2"
                        href={`mailto:${assignedRepresentative.email}`}
                      >
                        {assignedRepresentative.email}
                      </a>
                    </h4>
                    <h4>
                      <span className="font-semibold">Phone:</span>{" "}
                      <a
                        className="text-green-700 underline underline-offset-2"
                        href={`tel:${assignedRepresentative.phone}`}
                      >
                        {assignedRepresentative.phone}
                      </a>
                    </h4>
                  </div>
                ) : (
                  <h3 className="text-red-600 my-3 text-base">
                    Executive not assigned yet!
                  </h3>
                )}
              </div>
            </div>
            {/* Overlay */}
            {isOpen && (
              <div
                className={`fixed h-screen inset-0 bg-black bg-opacity-50 `}
                onClick={handleClick}
              ></div>
            )}
            <Link href="/">
              <Image src={logo} alt="Logo" className="w-[80px] md:w-[100px]" />
            </Link>
          </div>
          <div className="lg:flex hidden dropdown md:gap-x-5 text-white">
            <Link
              href={"/"}
              className={`px-1 -ml-7 md:ml-0 text-sm md:text-base ${
                pathName === "/" ||
                pathName === "/searchResult" ||
                pathName === "/bookFlights" ||
                pathName === "/confirmPage"
                  ? "font-bold"
                  : "hover:border-b"
              }`}
            >
              Flight
            </Link>
          </div>


          <div className="flex items-center space-x-2">
            {!isLoggedIn ? (
              <p>
                <Link
                  href="/signIn/register"
                  className="text-green-700 border bg-white py-1.5 px-3 rounded-md"
                >
                  Register
                </Link>
              </p>
            ) : (
              <div className="flex items-center gap-2 relative">
                <div className="md:flex gap-2 items-center absolute -left-60 hidden">
                  <div className="mr-3 text-white relative">
                    <h3
                      onClick={() => setIsContactPopupOpen(true)}
                      className="cursor-pointer hover:underline underline-offset-2"
                    >
                      Contact
                    </h3>
                    {isContactPopupOpen && (
                      <div className="absolute bg-white rounded-lg text-black min-w-max right-0 p-5 mt-1 shadow-xl">
                        <div className="flex justify-between mb-2">
                          <span className="font-semibold text-xl">
                            Your Executive
                          </span>

                          <button
                            onClick={() => setIsContactPopupOpen(false)}
                            className="bg-red-600 text-white p-1 rounded-full text-sm flex items-center justify-center"
                          >
                            <IoClose size={21} />
                          </button>
                        </div>
                        {assignedRepresentative ? (
                          <div className="space-y-2 mt-4">
                            <h4>
                              <span className="font-semibold">Name:</span>{" "}
                              {assignedRepresentative.name}
                            </h4>
                            <h4>
                              <span className="font-semibold">Email:</span>{" "}
                              {assignedRepresentative.email}
                            </h4>
                            <h4>
                              <span className="font-semibold">Phone:</span>{" "}
                              <a
                                className="text-green-700 underline underline-offset-2"
                                href={`tel:${assignedRepresentative.phone}`}
                              >
                                {assignedRepresentative.phone}
                              </a>
                            </h4>
                          </div>
                        ) : (
                          <h3 className="mt-4 text-lg text-red-600">
                            Executive not yet assigned.
                          </h3>
                        )}
                      </div>
                    )}
                  </div>
                  <h3 className="text-white">
                    Balance: {dashboardData?.accountBalance?.toLocaleString()}
                  </h3>
                </div>
                <Link href="/dashboard">
                  <div className="size-10 rounded-full overflow-hidden">
                    <Image
                      className="w-full h-full object-cover"
                      src={agentData?.companyLogo || userProfile}
                      width={50}
                      height={50}
                      alt="Picture of the author"
                    />
                  </div>
                </Link>
                {/* <span className="text-white">Welcome, {email}</span> */}
              </div>
            )}
          </div>
        </div>
      </nav>
    </main>
  );
};
export default Navbar;
