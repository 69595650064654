import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  data: [],
  error: "",
  destinationType: "one",
  departure: "",
  departureCode: [],
  arrival: "",
  arrivalCode: [],
  date: "",
  returnDate: "",
  travelerCounts: {
    adults: 1,
    children: 0,
    kids: 0,
    infants: 0,
  },
  filtering: {
    priceRange: 0,
    selectedTimes: [],
    selectedStops: [],
    selectedOption: null,
    selectedAirlines: [],
  },
};

const itinerarySearchSlice = createSlice({
  name: "itinerariesSearch",
  initialState,
  reducers: {
    callApiRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    callApiSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    callApiFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setDestinationType: (state, action) => {
      state.destinationType = action.payload;
    },
    setDeparture: (state, action) => {
      state.departure = action.payload;
    },
    setDepartureCode: (state, action) => {
      state.departureCode = action.payload;
    },
    setArrival: (state, action) => {
      state.arrival = action.payload;
    },
    setArrivalCode: (state, action) => {
      state.arrivalCode = action.payload;
    },
    setDate: (state, action) => {
      state.date = action.payload;
    },
    setReturnDate: (state, action) => {
      state.returnDate = action.payload;
    },
    setTravelerCounts: (state, action) => {
      state.travelerCounts = action.payload;
    },
    setSelectedTimes: (state, action) => {
      state.filtering.selectedTimes = action.payload;
    },
    setPriceRange: (state, action) => {
      state.filtering.priceRange = action.payload;
    },
    setSelectedStops: (state, action) => {
      state.filtering.selectedStops = action.payload;
    },
    setSelectedAirlines: (state, action) => {
      state.filtering.selectedAirlines = action.payload;
    },
    setSelectedOption: (state, action) => {
      state.filtering.selectedOption = action.payload;
    },
  },
});

export const {
  callApiRequest,
  callApiSuccess,
  callApiFailure,
  setDestinationType,
  setDeparture,
  setDepartureCode,
  setArrival,
  setArrivalCode,
  setDate,
  setReturnDate,
  setTravelerCounts,
  setPriceRange,
  setSelectedTimes,
  setSelectedStops,
  setSelectedOption,
  setSelectedAirlines,
} = itinerarySearchSlice.actions;

export default itinerarySearchSlice.reducer;
