"use client";
import { ReactNode, useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import store from "../redux/store";
import "./globals.css";
import { Poppins } from "next/font/google";
import Navbar from "@/components/Navbar";
import { Toaster } from "react-hot-toast";
// import Footer from "@/components/Footer";
import Footer from "@/components/Footer/Footer";
import { loadUserFromLocalStorage } from "@/redux/authSlice";
import AOS from "aos";
import "aos/dist/aos.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["300", "400", "500", "600", "700", "800", "900"],
});

type LayoutProps = {
  children: ReactNode;
};

const AuthInitializer = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserFromLocalStorage());
  }, [dispatch]);

  return <>{children}</>;
};

const Layout = ({ children }: LayoutProps) => {
  useEffect(() => {
    AOS.init({
      offset: 100,
      delay: 0,
      duration: 1000,
      easing: "ease",
      once: true,
    });
  }, []);

  return (
    <html lang="en">
      <body className={`${poppins.className} mx-auto`}>
        <Provider store={store}>
          <AuthInitializer>
            <GoogleOAuthProvider clientId="31811113314-02295110jf31ivqkf72sfcmvmleo8ii4.apps.googleusercontent.com">
              <Navbar />
              {children} 
              <Footer />
            </GoogleOAuthProvider>
          </AuthInitializer>
          <Toaster />
        </Provider>
      </body>
    </html>
  );
};

export default Layout;
